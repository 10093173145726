'use strict';

var angular = require('angular');

var assetHeroComponent = {
  bindings: {
    model: '<',
    onChange: '&',
    assetCount: '=',
    showControls: '=',
    annotation: '<' // optionally pass an annotation if it is available locally
  },
  controller: /*@ngInject*/ function(JobService) {
    var _self = this;

    _self.reset = function() {
      delete _self.heroImg;
      delete _self.annotationImg;
    };

    _self.handleLoad = function() {
      _self.loading = false;
    };

    _self.changeAsset = function(boolean) {
      _self.onChange({ $event: { boolean: boolean } });
      _self.reset();
    };

    _self.close = function() {
      _self.onChange({ $event: { close: true } });
      _self.reset();
    };

    _self.$onChanges = function(changes) {
      if (changes.model) {
        _self.model = angular.copy(_self.model);

        if (_self.model) {
          _self.loading = true;
          _self.heroImg = JobService.getImageByType(_self.model, 'large');

          // Force from D6 if downloadUrl otherwise force from Alfresco
          if (_self.model.downloadUrl) {
            _self.heroImg += '&sourceSystem=D6';
          } else {
            _self.heroImg += '&sourceSystem=ALFRESCO';
          }
          // If annotation isn't provided, attempt to fetch saved annotation from server
          if (!_self.annotation) {
            JobService.fetchAnnotation(_self.model).then(function(response) {
              if (!response.data) {
                return;
              } else if (response.data.length) {
                _self.annotationImg = response.data[0].img;
              }
            });
          } else if (_self.annotation && _self.annotation.length) {
            _self.annotationImg = angular.copy(_self.annotation[0].img);
          }
        }
      }

      if (changes.annotation) {
        if (changes.annotation.isFirstChange() || !_self.annotation) {
          return;
        }
        _self.annotationImg = angular.copy(_self.annotation[0].img);
      }
    };
  },
  templateUrl: 'templates/partials/detail/assetHero.tpl.html'
};

module.exports = assetHeroComponent;
