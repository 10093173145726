'use strict';

var angular = require('angular');
var initInjector = angular.injector(['ng']);
var $http = initInjector.get('$http');
var $log = initInjector.get('$log');
var $window = initInjector.get('$window');

var $q = angular.injector(['ng']).get('$q');

var REDIRECT_OKTA_URI = require('../config/redirectOktaURI.constant');
var OKTA_ISSUER = require('../config/oktaIssuer.constant');
var STORAGE_KEY = require('../config/storageKeyEnv.constant');
var EVENT_LOGGER_SERVICE = require('./eventLogger.service.js');
var get = require('lodash').get;

var OktaAuth = require('@okta/okta-auth-js').OktaAuth;
var authClient = null;
var EventLoggerService = EVENT_LOGGER_SERVICE && EVENT_LOGGER_SERVICE[2] && EVENT_LOGGER_SERVICE[2]({}, $window);

const minsTimeout = 60;

// local storage service looks for prefix 'ls.'
// session storage service uses 'ss.' prefix

var config = {
  // Required for login flow using getWithRedirect()
  clientId: 'nike.marketingeng.icon-web',
  // Required config
  issuer: OKTA_ISSUER.getOktaIssuer(),
  responseType: ['id_token', 'token'],
  scopes: ['openid', 'email', 'profile'],
  autoRenew: false,

  postLogoutRedirectUri: REDIRECT_OKTA_URI.getOktaRedirectURL(),
  redirectUri: REDIRECT_OKTA_URI.getOktaRedirectURL(),

  // Originally we configured TokenManager to use sessionStorage instead of localStorage. But this was not clearing the
  // tokens in other tabs, on user logout. SessionStorage will only be available to the current browser tab but
  // localstorage will be available to all browser tabs. So updating tokenManager to use localstorage.
  // https://github.com/okta/okta-auth-js#storagetype
  tokenManager: {
    storage: 'localStorage',
    storageKey: 'okta-icon-storage' + STORAGE_KEY.getStorageKeyEnv()
  }
};

authClient = new OktaAuth(config);

var renewToken = function(key, expiredToken) {
  $log.info('Renewing the token ' + key + ' - ' + JSON.stringify(expiredToken,null,2));

  authClient.session.refresh().then(function(session) {
    $log.info('okta session has been renewed - ' + JSON.stringify(session,null,2));
  });

  authClient.tokenManager.renew(key).then(function(newToken) {
    $log.info('\t renewed token ' + key + ' with okta.tokenManager - ' + JSON.stringify(newToken,null,2));
    if (EventLoggerService) {
      var userName = get(authClient, 'authStateManager._authState.idToken.claims.name', undefined);
      var email = get(authClient, 'authStateManager._authState.idToken.claims.email', undefined);

      EventLoggerService.track('OKTA:TOKEN RENEWAL', {
        userName: userName,
        email: email
      }, true);
    }

    if ('accessToken' === key) {
      setDefaultAuthorizationHeader();
      registerAuthRenewalTimer();
    }
  });
};

var clearState = function() {
  $window.sessionStorage.removeItem('ss.expires-at');
  if (EventLoggerService) {
    var userName = get(authClient, 'authStateManager._authState.idToken.claims.name', undefined);
    var email = get(authClient, 'authStateManager._authState.idToken.claims.email', undefined);

    EventLoggerService.track('OKTA:CLEAR STATE', {
      userName: userName,
      email: email
    }, true);
  }

  if ($window.sessionStorage.getItem('ss.auth-timeout-id') !== undefined) {
    $window.clearTimeout($window.sessionStorage.getItem('ss.auth-timeout-id'));
    $window.sessionStorage.removeItem('ss.auth-timeout-id');
  }
};

var logout = function (signoutOptions) {
  clearState();

  $log.info('logging out - revoking and clearing all tokens');

  return authClient.revokeAccessToken()
    .then(function (value) {
      if (EventLoggerService) {
        var userName = get(authClient, 'authStateManager._authState.idToken.claims.name', undefined);
        var email = get(authClient, 'authStateManager._authState.idToken.claims.email', undefined);

        EventLoggerService.track('OKTA:LOGOUT', {
          userName: userName,
          email: email
        }, true);
      }
      authClient.signOut(signoutOptions)
        .then(function (value) {
          authClient.tokenManager.clear();
        })
        .catch((err) => {
          var error = 'Okta sign-out Error: ';
          if (err.message) {
            error += err.message;
          }
          if (err.stack) {
            error += ' | stack: ' + err.stack;
          }
          $log.error(error);
          if (EventLoggerService) {
            var userName = get(authClient, 'authStateManager._authState.idToken.claims.name', undefined);
            var email = get(authClient, 'authStateManager._authState.idToken.claims.email', undefined);

            EventLoggerService.track('OKTA:LOGOUT ERROR', {
              userName: userName,
              email: email
            }, true);
          }
        });
    })
    .catch((err) => {
      $log.error('logout revokeAccessToken error: ' + err);
    });
};

var authenticate = function () {
  if (authClient.isLoginRedirect()) {
    var deferred = $q.defer();

    // Parse token from redirect url
    authClient.token.parseFromUrl()
      .then(function (value) {
        var tokens = value.tokens;

        // Store parsed token in Token Manager
        authClient.tokenManager.setTokens(tokens);
        setDefaultAuthorizationHeader();
        registerAuthRenewalTimer();

        if (EventLoggerService) {
          var email = get(value, 'tokens.accessToken.claims.sub', undefined);
          EventLoggerService.track('OKTA:AUTHENTICATE', {
            email: email,
          }, true);
        }

        if (deferred) {
          deferred.resolve();
        }
      }, function (error) {
        $log.error('isLoginRedirect Error in auth: ' + error);
        logout();
      });

    return deferred.promise;
  }

  return authClient.isAuthenticated()
    .then(function (value) {
      if (!value) {
        // add to pre-redirect local storage
        $window.localStorage.setItem('ls.pre-redirect', window.location.href);
        authClient.signInWithRedirect();
      } else {
        setDefaultAuthorizationHeader();
        registerAuthRenewalTimer();
      }
    }, function (error) {
      $log.error('isAuthenticated Error in auth: ' + error);
      logout();
    });
};

var isAuthenticated = function () {
  //ignore tests
  if (location.href.indexOf(':9876') > -1 || location.href.indexOf(':3010') > -1) {
    return true;
  }

  return authClient.isAuthenticated()
  .then(function(value) {
      if (!value) {
        $log.info('okta says accessToken is expired, logging out');
        logout();
      }

      // in case the renewal time has expired, but another tab already got a new token
      if(!hasAuthRenewalTimer()) {
        registerAuthRenewalTimer();
      }

      updateActive();
      return value;
    });
}

function updateActive() {
  $window.localStorage.setItem('ls.last-activity', new Date());
}

function isInactive() {
  const lastActivity = $window.localStorage.getItem('ls.last-activity');
  if (lastActivity && lastActivity.length > 5) {
    const timeDiff = new Date() - new Date(lastActivity);
    if (!isNaN(timeDiff)) {
      if (timeDiff >  minsTimeout * 60 * 1000) {
        return true;
      }
    } else {
      $log.error('checkInactive timeDiff is invalid: ' + timeDiff);
    }
  }
  return false;
}

var fetchAccessToken = function () {
  if (location.href.indexOf(':9876') > -1 || location.href.indexOf(':3010') > -1) {
    return {accessToken: ' '};
  }
  return authClient.tokenManager.getSync('accessToken');
};

var fetchAuthToken = function () {
  var token = fetchAccessToken();

  if (token !== undefined) {
    return 'Bearer ' + token.accessToken;
  }

  return null;
};

var setDefaultAuthorizationHeader = function() {
  $http.defaults.headers.common['Authorization'] = fetchAuthToken();
};

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

function isAccessTokenExpired() {
  var accessToken = fetchAccessToken();
  var now = new Date();

  return (accessToken === undefined) ? true : (now > (accessToken.expiresAt * 1000));
}

function hasAuthRenewalTimer() {
  return $window.sessionStorage.getItem('ss.auth-timeout-id') != null;
}

var registerAuthRenewalTimer = function() {
  var accessToken = fetchAccessToken();
  var now = new Date();

  if (accessToken && accessToken.expiresAt > 0) {
    var randomDelay = ((15 * 60) + getRandomInt(1, 300)); // 15min + 0-5min (in seconds)
    var maxDelay = Math.floor(((accessToken.expiresAt * 1000) - now.getTime()) / 1000) - 30 - getRandomInt(0,30);  //30-60s = extra buffer for last second renewal
    var delay = (randomDelay < maxDelay) ? randomDelay : maxDelay;

    $log.info('expiration: ' + accessToken.expiresAt + ', maxDelay: ' + maxDelay + 'ms, actualDelay: ' + delay + ' seconds from now');

    $window.sessionStorage.setItem('ss.expires-at', accessToken.expiresAt);
    var timeoutId = setTimeout(doRenewalIfNeeded, delay * 1000);
    $window.sessionStorage.setItem('ss.auth-timeout-id', timeoutId);
  } else {
    $log.info('unable to register renewal timer, expiration occurs in the past');
  }
};

var doRenewalIfNeeded = function() {
  $window.sessionStorage.removeItem('ss.auth-timeout-id');

  if (isInactive()) {
    $log.info('user is inactive, leaving session to expire...');
    if (EventLoggerService) {
      var userName = get(authClient, 'authStateManager._authState.idToken.claims.name', undefined);
      var email = get(authClient, 'authStateManager._authState.idToken.claims.email', undefined);

      EventLoggerService.track('OKTA:INACTIVE USER', {
        userName: userName,
        email: email
      }, true);
    }
    return;
  }

  var accessToken = fetchAccessToken();
  var expectedExpiresAt = parseInt($window.sessionStorage.getItem('ss.expires-at'));

  if (accessToken && accessToken.expiresAt === expectedExpiresAt) {
    renewToken('accessToken', accessToken);
  } else {
    if (accessToken === undefined) {
      $log.info('accessToken is not defined, skipping this renewal, but registering new timer...');
    } else {
      $log.info('token has already been renewed(from another tab), registering new timer...');
    }

    registerAuthRenewalTimer();
  }
};

module.exports = {
  authenticate: authenticate,
  fetchAuthToken: fetchAuthToken,
  logout: logout,
  isAuthenticated: isAuthenticated,
  isAccessTokenExpired: isAccessTokenExpired,
};
